<template>
  <div :class="echartData.noData == false?'chart-plate':''">
    <div style="width:100%;height:calc(( 100vh - 65px ) / 2 );margin-bottom:60px" :id="id"></div>
  </div>
</template>
<script>
import echarts from 'echarts';
export default {
  components: {},
  props: {
    id: {
      type: String,
      default: ''
    },
    echartData: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
    echartData: {
      handler(newVal, oldVal) {
        this.loadLineEchart();
      },
      deep: true
    }
  },
  methods: {

    loadLineEchart() {
      let that = this;

      var myChat = echarts.init(document.getElementById(this.id));
      let series = [];
      this.echartData.series.forEach((item, index) => {
        let data = {
          name: this.echartData.series[index].name,
          type: "line",
          smooth: true,
          symbolSize: 16,
          zlevel: 3,
          lineStyle: {
            normal: {
              width: 16,
              color: this.echartData.series[index].color,
            }
          },
          symbol: 'circle',
          itemStyle: {
            color: this.echartData.series[index].color,
            borderColor: "#fff",
            borderWidth: 3,
          },
          data: this.echartData.series[index].yAxisData
        }
        series.push(data);
      });

      // y轴第二个是否设置最大值
      const isAllInvalid = this.echartData.series[1].yAxisData.every(item => !item);

      myChat.setOption({
        title: {
          text: this.echartData.title,
          textStyle: {
            color: '#333333',
            fontSize: 14,
          },
          top: 0,
          left: 0,
        },
        legend: {
          left: 0,
          top: 0
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let html = `<div style="width: 160px">${params[0].name}`;
            params.forEach(v => {
              html += `<div>
                <span style="display:inline-block;margin-right:2px;border-radius:6px;width:6px;height:6px;background:${v.color};transform: translateY(-2px);"></span>
                ${v.seriesName}
                : <span style="float: right;">${v.value ? v.value + '%' : ''}</span></div>`;
            })
            html += `</div>`
            return html
          },
          extraCssText: 'background: #fff; border-radius: 4px;box-shadow: 0 0 6px rgba(0, 21, 41, 0.08);color: #333;',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(193, 212, 253, 0.4)',
            }
          },
        },
        grid: {
          containLabel: true,
          top: 70,
          left: 0,
          right: 0,
          bottom: 20
        },
        xAxis: [{
          type: "category",
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#2B3441"
            }
          },
          axisLine: {
            lineStyle: {
              color: "rgba(65, 96, 128, 0.2)"
            }
          },
          data: this.echartData.xAxisData
        }],
        yAxis: [
          {
            type: "value",
            min: 0, // 设置y轴最小值为0
            max: 100, // 设置y轴最大值为100
            axisLabel: {
              formatter: '{value} %',
              textStyle: {
                color: "#2B3441"
              }
            },
            nameTextStyle: {
              color: "#2B3441",
              fontSize: 14,
            },
            splitLine: {
              lineStyle: {
                color: "#E9EDF1"
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }],
        series: series
      });
      window.addEventListener('resize', () => {
        myChat.resize();
      });
    },
  },
};
</script>
<style scoped lang="less">
.chart-plate {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: -4px;
    width: 3px;
    height: 14px;
    border-radius: 14px;
    background: #3e8bfe;
  }
  &::after {
    content: "";
    position: absolute;
    top: 26px;
    right: 0;
    width: calc(100% - 10px);
    height: 1px;
    border-bottom: 1px solid #dddddd;
  }
}
</style>